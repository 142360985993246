/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface State
 */
export interface State {
    /**
     * 
     * @type {string}
     * @memberof State
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof State
     */
    name: string;
}

/**
 * Check if a given object implements the State interface.
 */
export function instanceOfState(value: object): value is State {
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function StateFromJSON(json: any): State {
    return StateFromJSONTyped(json, false);
}

export function StateFromJSONTyped(json: any, ignoreDiscriminator: boolean): State {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
    };
}

export function StateToJSON(json: any): State {
    return StateToJSONTyped(json, false);
}

export function StateToJSONTyped(value?: State | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'code': value['code'],
        'name': value['name'],
    };
}

