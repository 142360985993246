import * as Sentry from "@sentry/react";
import {Route} from 'react-router-dom';
import Loader from './common/Loader';
import {ApiProvider} from './contexts/ApiContext';
import {LoadingProvider, useLoading} from './contexts/LoadingContext';
import {AuthProvider, useAuth} from './contexts/AuthContext';
import {ColorModeProvider} from './contexts/ColorModeContext';
import ForgotPassword from './pages/Authentication/ForgotPassword.tsx';
import SignIn from './pages/Authentication/SignIn.tsx';
import SignUp from './pages/Authentication/SignUp.tsx';
import ErrorPage from './pages/ErrorPage';
import PropertyDetail from './pages/Property/PropertyDetail.tsx';
import PropertyLists from './pages/PropertyList/PropertyLists.tsx';
import User from './pages/Settings/User.tsx';
import ProtectedRoute from './routes/ProtectedRoutes';
import PublicRoute from './routes/PublicRoutes';
import {SentryRoutes} from './sentry';
import ResetPassword from "./pages/Authentication/ResetPassword.tsx";
import Teams from "./pages/Settings/Teams.tsx";
import PropertyMarkets from "./pages/Property/PropertyMarkets.tsx";


function App() {
    return (
        <LoadingProvider>
            <ColorModeProvider>
                <ApiProvider>
                    <AuthProvider>
                        <AppRouter/>
                    </AuthProvider>
                </ApiProvider>
            </ColorModeProvider>
        </LoadingProvider>
    );
}

const AppRouter: React.FC = () => {
    const {isInitialized, user} = useAuth();
    const {isLoading} = useLoading();
    const showLoader = isLoading || (!isInitialized && user !== null);

    if (!isInitialized) {
        return <Loader/>;
    }

    return (
        <>
            {showLoader && <Loader/>}
            <SentryRoutes>
                {/* Public routes */}
                <Route element={<PublicRoute/>}>
                    <Route path="/auth/signin" element={<SignIn/>}/>
                    <Route path="/auth/signup" element={<SignUp/>}/>
                    <Route path="/auth/forgot-password" element={<ForgotPassword/>}/>
                    <Route path="/auth/reset-password" element={<ResetPassword/>}/>
                </Route>

                {/* Protected routes */}
                <Route element={<ProtectedRoute/>}>
                    <Route path="/" element={<PropertyMarkets/>}/>

                    <Route path="/property/search" element={<PropertyMarkets/>}/>
                    <Route path="/property/:id" element={<PropertyDetail/>}/>
                    <Route path="/property/lists" element={<PropertyLists/>}/>

                    <Route path="/settings/user" element={<User/>}/>
                    <Route path="/settings/team" element={<Teams/>}/>
                    {/* Add your other protected routes here */}
                </Route>

                {/* Fallback route for 404 */}
                <Route path="*" element={<ErrorPage/>}/>
            </SentryRoutes>
        </>
    );
};

export default Sentry.withProfiler(App);