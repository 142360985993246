/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthForgotPasswordRequest,
  AuthLoginRequest,
  AuthRefreshRequest,
  AuthResetPasswordRequest,
  AuthTokensResponse,
  ErrorResponse,
} from '../models/index';
import {
    AuthForgotPasswordRequestFromJSON,
    AuthForgotPasswordRequestToJSON,
    AuthLoginRequestFromJSON,
    AuthLoginRequestToJSON,
    AuthRefreshRequestFromJSON,
    AuthRefreshRequestToJSON,
    AuthResetPasswordRequestFromJSON,
    AuthResetPasswordRequestToJSON,
    AuthTokensResponseFromJSON,
    AuthTokensResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models/index';

export interface AuthForgotPasswordOperationRequest {
    authForgotPasswordRequest: AuthForgotPasswordRequest;
}

export interface AuthLoginOperationRequest {
    authLoginRequest: AuthLoginRequest;
}

export interface AuthRefreshOperationRequest {
    authRefreshRequest: AuthRefreshRequest;
}

export interface AuthResetPasswordOperationRequest {
    authResetPasswordRequest: AuthResetPasswordRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Sends a forgot password email to the email
     * Sends a forgot password email to the email
     */
    async authForgotPasswordRaw(requestParameters: AuthForgotPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authForgotPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'authForgotPasswordRequest',
                'Required parameter "authForgotPasswordRequest" was null or undefined when calling authForgotPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthForgotPasswordRequestToJSON(requestParameters['authForgotPasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends a forgot password email to the email
     * Sends a forgot password email to the email
     */
    async authForgotPassword(requestParameters: AuthForgotPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authForgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Logs user into the system
     * Logs user into the system
     */
    async authLoginRaw(requestParameters: AuthLoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthTokensResponse>> {
        if (requestParameters['authLoginRequest'] == null) {
            throw new runtime.RequiredError(
                'authLoginRequest',
                'Required parameter "authLoginRequest" was null or undefined when calling authLogin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthLoginRequestToJSON(requestParameters['authLoginRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthTokensResponseFromJSON(jsonValue));
    }

    /**
     * Logs user into the system
     * Logs user into the system
     */
    async authLogin(requestParameters: AuthLoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthTokensResponse> {
        const response = await this.authLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a new pair of access and refresh tokens
     * Refreshes a users auth
     */
    async authRefreshRaw(requestParameters: AuthRefreshOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthTokensResponse>> {
        if (requestParameters['authRefreshRequest'] == null) {
            throw new runtime.RequiredError(
                'authRefreshRequest',
                'Required parameter "authRefreshRequest" was null or undefined when calling authRefresh().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthRefreshRequestToJSON(requestParameters['authRefreshRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthTokensResponseFromJSON(jsonValue));
    }

    /**
     * Returns a new pair of access and refresh tokens
     * Refreshes a users auth
     */
    async authRefresh(requestParameters: AuthRefreshOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthTokensResponse> {
        const response = await this.authRefreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resets the password for an account
     * Resets the password for an account
     */
    async authResetPasswordRaw(requestParameters: AuthResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authResetPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'authResetPasswordRequest',
                'Required parameter "authResetPasswordRequest" was null or undefined when calling authResetPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthResetPasswordRequestToJSON(requestParameters['authResetPasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resets the password for an account
     * Resets the password for an account
     */
    async authResetPassword(requestParameters: AuthResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authResetPasswordRaw(requestParameters, initOverrides);
    }

}
