/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Market } from './Market';
import {
    MarketFromJSON,
    MarketFromJSONTyped,
    MarketToJSON,
    MarketToJSONTyped,
} from './Market';

/**
 * 
 * @export
 * @interface PaginatedMarketsResponse
 */
export interface PaginatedMarketsResponse {
    /**
     * 
     * @type {Array<Market>}
     * @memberof PaginatedMarketsResponse
     */
    data: Array<Market>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMarketsResponse
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMarketsResponse
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMarketsResponse
     */
    offset: number;
}

/**
 * Check if a given object implements the PaginatedMarketsResponse interface.
 */
export function instanceOfPaginatedMarketsResponse(value: object): value is PaginatedMarketsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('limit' in value) || value['limit'] === undefined) return false;
    if (!('offset' in value) || value['offset'] === undefined) return false;
    return true;
}

export function PaginatedMarketsResponseFromJSON(json: any): PaginatedMarketsResponse {
    return PaginatedMarketsResponseFromJSONTyped(json, false);
}

export function PaginatedMarketsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedMarketsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(MarketFromJSON)),
        'count': json['count'],
        'limit': json['limit'],
        'offset': json['offset'],
    };
}

export function PaginatedMarketsResponseToJSON(json: any): PaginatedMarketsResponse {
    return PaginatedMarketsResponseToJSONTyped(json, false);
}

export function PaginatedMarketsResponseToJSONTyped(value?: PaginatedMarketsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(MarketToJSON)),
        'count': value['count'],
        'limit': value['limit'],
        'offset': value['offset'],
    };
}

