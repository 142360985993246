/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertyListCreateRequest
 */
export interface PropertyListCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyListCreateRequest
     */
    name: string;
}

/**
 * Check if a given object implements the PropertyListCreateRequest interface.
 */
export function instanceOfPropertyListCreateRequest(value: object): value is PropertyListCreateRequest {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function PropertyListCreateRequestFromJSON(json: any): PropertyListCreateRequest {
    return PropertyListCreateRequestFromJSONTyped(json, false);
}

export function PropertyListCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyListCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function PropertyListCreateRequestToJSON(json: any): PropertyListCreateRequest {
    return PropertyListCreateRequestToJSONTyped(json, false);
}

export function PropertyListCreateRequestToJSONTyped(value?: PropertyListCreateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
    };
}

