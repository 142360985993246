/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthTokensResponse,
  ErrorResponse,
  UserCreateRequest,
  UserMeResponse,
} from '../models/index';
import {
    AuthTokensResponseFromJSON,
    AuthTokensResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    UserCreateRequestFromJSON,
    UserCreateRequestToJSON,
    UserMeResponseFromJSON,
    UserMeResponseToJSON,
} from '../models/index';

export interface UserCreateOperationRequest {
    userCreateRequest: UserCreateRequest;
}

export interface UsersMeUpdateRequest {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    photo?: Blob;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Creates a user
     * Create a user
     */
    async userCreateRaw(requestParameters: UserCreateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthTokensResponse>> {
        if (requestParameters['userCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'userCreateRequest',
                'Required parameter "userCreateRequest" was null or undefined when calling userCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserCreateRequestToJSON(requestParameters['userCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthTokensResponseFromJSON(jsonValue));
    }

    /**
     * Creates a user
     * Create a user
     */
    async userCreate(requestParameters: UserCreateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthTokensResponse> {
        const response = await this.userCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logs user into the system
     * Logs user into the system
     */
    async usersMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMeResponseFromJSON(jsonValue));
    }

    /**
     * Logs user into the system
     * Logs user into the system
     */
    async usersMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMeResponse> {
        const response = await this.usersMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Logs user into the system
     * Updates currently authenticated user
     */
    async usersMeUpdateRaw(requestParameters: UsersMeUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['firstName'] != null) {
            formParams.append('first_name', requestParameters['firstName'] as any);
        }

        if (requestParameters['lastName'] != null) {
            formParams.append('last_name', requestParameters['lastName'] as any);
        }

        if (requestParameters['phoneNumber'] != null) {
            formParams.append('phone_number', requestParameters['phoneNumber'] as any);
        }

        if (requestParameters['photo'] != null) {
            formParams.append('photo', requestParameters['photo'] as any);
        }

        const response = await this.request({
            path: `/users/me`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMeResponseFromJSON(jsonValue));
    }

    /**
     * Logs user into the system
     * Updates currently authenticated user
     */
    async usersMeUpdate(requestParameters: UsersMeUpdateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMeResponse> {
        const response = await this.usersMeUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
