/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  MyTeamResponse,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    MyTeamResponseFromJSON,
    MyTeamResponseToJSON,
} from '../models/index';

/**
 * 
 */
export class TeamsApi extends runtime.BaseAPI {

    /**
     * Gets the team for the current user
     * Gets the team for the current user
     */
    async myTeamRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MyTeamResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/mine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyTeamResponseFromJSON(jsonValue));
    }

    /**
     * Gets the team for the current user
     * Gets the team for the current user
     */
    async myTeam(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MyTeamResponse> {
        const response = await this.myTeamRaw(initOverrides);
        return await response.value();
    }

}
