import React from 'react';
import {Globe, MapPin, X} from 'lucide-react';

interface MarketBreadcrumbProps {
    countryCode?: string | null;
    state?: string | null;
    city?: string | null;
    onClearFilter: (level?: 'country' | 'state' | 'city') => void;
}

const MarketBreadcrumb: React.FC<MarketBreadcrumbProps> = ({
    countryCode,
    state,
    city,
    onClearFilter
}) => {
    return (
        <div className="rounded-md border border-stroke bg-whiter p-3 sm:p-4 mb-6 dark:border-strokedark dark:bg-meta-4">
            <nav className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 sm:gap-0">
                <ol className="flex flex-wrap items-center gap-2 sm:gap-3">
                    <li>
                        <button
                            onClick={() => onClearFilter()}
                            className="flex items-center gap-2 text-sm sm:text-base font-medium text-black hover:text-primary dark:text-white dark:hover:text-primary transition-colors duration-200"
                        >
                            <Globe className="h-5 w-5" />
                            Global
                        </button>
                    </li>
                    
                    {countryCode && (
                        <li className="flex items-center gap-3">
                            <svg
                                className="fill-current"
                                width="18"
                                height="7"
                                viewBox="0 0 18 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.5704 2.58734L14.8227 0.510459C14.6708 0.333165 14.3922 0.307837 14.1896 0.459804C14.0123 0.61177 13.9869 0.890376 14.1389 1.093L15.7852 3.04324H1.75361C1.50033 3.04324 1.29771 3.24586 1.29771 3.49914C1.29771 3.75241 1.50033 3.95504 1.75361 3.95504H15.7852L14.1389 5.90528C13.9869 6.08257 14.0123 6.36118 14.1896 6.53847C14.2655 6.61445 14.3668 6.63978 14.4682 6.63978C14.5948 6.63978 14.7214 6.58913 14.7974 6.48782L16.545 4.41094C17.0009 3.85373 17.0009 3.09389 16.5704 2.58734Z"
                                    fill=""
                                />
                            </svg>
                            <div className="flex items-center gap-2">
                                <img
                                    src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
                                    alt={`${countryCode} flag`}
                                    className="h-4"
                                />
                                <span className="font-medium">{countryCode}</span>
                                <button
                                    onClick={() => onClearFilter('country')}
                                    className="ml-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 p-1 transition-colors duration-200"
                                    title="Clear country filter"
                                >
                                    <X className="h-3 w-3 sm:h-4 sm:w-4" />
                                </button>
                            </div>
                        </li>
                    )}

                    {state && (
                        <li className="flex items-center gap-3">
                            <svg
                                className="fill-current"
                                width="18"
                                height="7"
                                viewBox="0 0 18 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.5704 2.58734L14.8227 0.510459C14.6708 0.333165 14.3922 0.307837 14.1896 0.459804C14.0123 0.61177 13.9869 0.890376 14.1389 1.093L15.7852 3.04324H1.75361C1.50033 3.04324 1.29771 3.24586 1.29771 3.49914C1.29771 3.75241 1.50033 3.95504 1.75361 3.95504H15.7852L14.1389 5.90528C13.9869 6.08257 14.0123 6.36118 14.1896 6.53847C14.2655 6.61445 14.3668 6.63978 14.4682 6.63978C14.5948 6.63978 14.7214 6.58913 14.7974 6.48782L16.545 4.41094C17.0009 3.85373 17.0009 3.09389 16.5704 2.58734Z"
                                    fill=""
                                />
                            </svg>
                            <div className="flex items-center gap-2">
                                <MapPin className="h-4 w-4" />
                                <span className="font-medium">{state}</span>
                                <button
                                    onClick={() => onClearFilter('state')}
                                    className="ml-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 p-1"
                                    title="Clear state filter"
                                >
                                    <X className="h-4 w-4" />
                                </button>
                            </div>
                        </li>
                    )}

                    {city && (
                        <li className="flex items-center gap-3">
                            <svg
                                className="fill-current"
                                width="18"
                                height="7"
                                viewBox="0 0 18 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.5704 2.58734L14.8227 0.510459C14.6708 0.333165 14.3922 0.307837 14.1896 0.459804C14.0123 0.61177 13.9869 0.890376 14.1389 1.093L15.7852 3.04324H1.75361C1.50033 3.04324 1.29771 3.24586 1.29771 3.49914C1.29771 3.75241 1.50033 3.95504 1.75361 3.95504H15.7852L14.1389 5.90528C13.9869 6.08257 14.0123 6.36118 14.1896 6.53847C14.2655 6.61445 14.3668 6.63978 14.4682 6.63978C14.5948 6.63978 14.7214 6.58913 14.7974 6.48782L16.545 4.41094C17.0009 3.85373 17.0009 3.09389 16.5704 2.58734Z"
                                    fill=""
                                />
                            </svg>
                            <div className="flex items-center gap-2">
                                <MapPin className="h-4 w-4" />
                                <span className="font-medium">{city}</span>
                                <button
                                    onClick={() => onClearFilter('city')}
                                    className="ml-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 p-1"
                                    title="Clear city filter"
                                >
                                    <X className="h-4 w-4" />
                                </button>
                            </div>
                        </li>
                    )}
                </ol>
                
                {(countryCode || state || city) && (
                    <button
                        onClick={() => onClearFilter()}
                        className="w-full sm:w-auto flex items-center justify-center gap-2 text-sm font-medium text-danger hover:text-danger-hover bg-danger/5 hover:bg-danger/10 rounded-md px-3 py-1.5 transition-colors duration-200"
                    >
                        <X className="h-3.5 w-3.5" />
                        Clear all filters
                    </button>
                )}
            </nav>
        </div>
    );
};

export default MarketBreadcrumb;
