import {ChevronDown, LogOut, Settings, Users} from 'lucide-react';
import {useMemo, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useAuth} from '../../contexts/AuthContext';
import userThree from '../../images/user/user-01.png';
import useOutsideClick from "../../hooks/useOutsideClick.tsx";

const DropdownUser = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const {user, logout} = useAuth();

    const fullName = useMemo(() => {
        return user ? `${user?.firstName} ${user?.lastName}` : 'Your Name';
    }, [user]);

    const navigate = useNavigate();

    const dropDownRef = useOutsideClick(() => setDropdownOpen(false));


    const handleLogout = () => {
        logout();
        navigate('/auth/signin');
    };

    return (
        <div className="relative" ref={dropDownRef}>
            <Link
                className="flex items-center gap-4"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                to="#"
            >
                <span className="hidden text-right lg:block">
                    <span className="block text-sm font-medium text-black dark:text-white">
                        {fullName.length > 2 ? `${fullName}` : 'Your Name'}
                    </span>
                </span>

                <span className="h-12 w-12 rounded-full">
                    <LazyLoadImage
                        src={user?.photo || userThree}
                        alt="User Image"
                        effect="blur"
                        className="h-full w-full object-cover rounded-full"
                    />
                </span>

                <ChevronDown
                    className={`h-5 w-5 stroke-current text-black dark:text-white ${dropdownOpen ? 'rotate-180' : ''}`}/>
            </Link>

            {/* <!-- Dropdown Start --> */}
            <div
                onFocus={() => setDropdownOpen(true)}
                onBlur={() => setDropdownOpen(false)}
                className={`absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark ${dropdownOpen === true ? 'block' : 'hidden'
                }`}
            >
                <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-7.5 dark:border-strokedark">
                    <li>
                        <Link
                            to="/settings/user"
                            className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
                        >
                            <Settings size={18}/>
                            Settings
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/settings/team"
                            className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
                        >
                            <Users size={18}/>
                            Team
                        </Link>
                    </li>
                </ul>
                <button
                    onClick={handleLogout}
                    className="flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base">
                    <LogOut size={18}/>
                    Log Out
                </button>
            </div>
            {/* <!-- Dropdown End --> */}
        </div>
    );
};

export default DropdownUser;
