/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PaginatedMarketsResponse,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PaginatedMarketsResponseFromJSON,
    PaginatedMarketsResponseToJSON,
} from '../models/index';

export interface MarketsRequest {
    limit?: number;
    offset?: number;
    countryCode?: string;
    state?: string;
    city?: string;
}

/**
 * 
 */
export class MarketsApi extends runtime.BaseAPI {

    /**
     * Returns a list of Markets for a given country code, state, and/or city
     * Returns a list of Markets for a given country code, state, and/or city
     */
    async marketsRaw(requestParameters: MarketsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedMarketsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['countryCode'] != null) {
            queryParameters['country_code'] = requestParameters['countryCode'];
        }

        if (requestParameters['state'] != null) {
            queryParameters['state'] = requestParameters['state'];
        }

        if (requestParameters['city'] != null) {
            queryParameters['city'] = requestParameters['city'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/markets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedMarketsResponseFromJSON(jsonValue));
    }

    /**
     * Returns a list of Markets for a given country code, state, and/or city
     * Returns a list of Markets for a given country code, state, and/or city
     */
    async markets(requestParameters: MarketsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedMarketsResponse> {
        const response = await this.marketsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
