import {ChevronDown, ChevronLeft, Heart, House, List, Search} from 'lucide-react';
import React, {useEffect, useRef} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import LogoFullWhite from '../../images/logo/logo-full-white.svg';
import SidebarLinkGroup from './SidebarLinkGroup';

interface SidebarProps {
    sidebarOpen: boolean;
    toggleSidebar: () => void;
}

const Sidebar = ({sidebarOpen, toggleSidebar}: SidebarProps) => {
    const location = useLocation();
    const {pathname} = location;

    const [isHovering, setIsHovering] = React.useState(false);

    const wasInitiallyClosed = useRef(false);

    const handleMouseEnter = () => {
        wasInitiallyClosed.current = !sidebarOpen;
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
        if (wasInitiallyClosed.current) {
            toggleSidebar();
        }
    };

    useEffect(() => {
        if (isHovering && !sidebarOpen) {
            toggleSidebar();
        }
    }, [isHovering]);


    return (
        <aside
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={`
            absolute left-0 top-0 z-9999 flex h-screen 
            w-72.5 flex-col overflow-y-hidden 
            bg-black duration-300 ease-linear 
            dark:bg-boxdark lg:static lg:translate-x-0 
            ${sidebarOpen ? 'w-72.5' : 'w-15 lg:w-15'} 
            ${!sidebarOpen && 'lg:translate-x-0 -translate-x-full'}
            lg:static
            `}
        >
            {/* <!-- SIDEBAR HEADER --> */}
            <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5">
                <NavLink to="/">
                    <img className="h-7.5" src={LogoFullWhite} alt="Vercasa white logo"/>
                </NavLink>


                <button
                    onClick={toggleSidebar}
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    className="block"
                >
                    <ChevronLeft
                        className={`
                            w-5 h-5 text-bodydark1 transition-transform duration-300
                            ${!sidebarOpen && 'rotate-180'}
                            ${!sidebarOpen && 'lg:ml-2'}
                        `}/>
                </button>
            </div>

            <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                <nav className={`
                    mt-5 py-4 px-4 lg:mt-9 ${!sidebarOpen && 'px-0'}
                `}
                >
                    <div>
                        {sidebarOpen && (
                            <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
                                MENU
                            </h3>
                        )}

                        <ul className="mb-6 flex flex-col gap-1.5">

                            {/* <!-- Menu Item Task --> */}
                            <SidebarLinkGroup
                                activeCondition={
                                    pathname === '/property' || pathname.includes('property')
                                }
                            >
                                {(handleClick, open) => {
                                    return (
                                        <React.Fragment>
                                            <NavLink
                                                to="#"
                                                className={`
                                                    group relative flex items-center gap-2.5 rounded-sm 
                                                    py-2 px-4 font-medium text-bodydark1 duration-300 
                                                    ease-in-out hover:bg-graydark dark:hover:bg-meta-4 
                                                    ${(pathname === '/property' || pathname.includes('property')) && 'bg-graydark dark:bg-meta-4'}
                                                    ${!sidebarOpen && 'justify-center'}
                                                `}
                                                onClick={handleClick}
                                            >

                                                <House className="h-5 w-5 min-w-[20px]" size={18}/>

                                                {sidebarOpen && (
                                                    <>
                                                        <span>Property</span>
                                                        <ChevronDown
                                                            className={`absolute right-4 top-1/2 -translate-y-1/2 h-5 w-5 stroke-current text-black dark:text-white ${open ? 'rotate-180' : ''}`}/>
                                                    </>
                                                )}
                                            </NavLink>
                                            {/* <!-- Dropdown Menu Start --> */}
                                            <div
                                                className={`translate transform overflow-hidden ${(!open || !sidebarOpen) && 'hidden'}`}
                                            >
                                                <ul className="mt-4 mb-5.5 flex flex-col gap-2.5 pl-6">
                                                    <li>
                                                        <NavLink
                                                            to="/property/search"
                                                            className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('search') && 'bg-graydark dark:bg-meta-4'}`}
                                                        >
                                                            <Search size={18}/>
                                                            Search
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink
                                                            to="/property/lists"
                                                            className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('lists') && 'bg-graydark dark:bg-meta-4'}`}
                                                        >
                                                            <List size={18}/>
                                                            Lists
                                                        </NavLink>
                                                    </li>

                                                    <li>
                                                        <NavLink
                                                            to="/property/favorites"
                                                            className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('favorites') && 'bg-graydark dark:bg-meta-4'}`}
                                                        >
                                                            <Heart size={18}/>
                                                            Favorites
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* <!-- Dropdown Menu End --> */}
                                        </React.Fragment>
                                    );
                                }}
                            </SidebarLinkGroup>
                        </ul>
                    </div>
                </nav>
                {/* <!-- Sidebar Menu --> */}
            </div>
        </aside>
    );
};

export default Sidebar;
