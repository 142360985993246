/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Market
 */
export interface Market {
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Market
     */
    city: string;
    /**
     * 
     * @type {number}
     * @memberof Market
     */
    totalProperties: number;
    /**
     * 
     * @type {number}
     * @memberof Market
     */
    averageProfit: number;
    /**
     * 
     * @type {number}
     * @memberof Market
     */
    averageDailyRate: number;
    /**
     * 
     * @type {number}
     * @memberof Market
     */
    averageOccupancy: number;
    /**
     * 
     * @type {number}
     * @memberof Market
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof Market
     */
    longitude: number;
}

/**
 * Check if a given object implements the Market interface.
 */
export function instanceOfMarket(value: object): value is Market {
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('countryCode' in value) || value['countryCode'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('totalProperties' in value) || value['totalProperties'] === undefined) return false;
    if (!('averageProfit' in value) || value['averageProfit'] === undefined) return false;
    if (!('averageDailyRate' in value) || value['averageDailyRate'] === undefined) return false;
    if (!('averageOccupancy' in value) || value['averageOccupancy'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    return true;
}

export function MarketFromJSON(json: any): Market {
    return MarketFromJSONTyped(json, false);
}

export function MarketFromJSONTyped(json: any, ignoreDiscriminator: boolean): Market {
    if (json == null) {
        return json;
    }
    return {
        
        'country': json['country'],
        'countryCode': json['country_code'],
        'state': json['state'],
        'city': json['city'],
        'totalProperties': json['total_properties'],
        'averageProfit': json['average_profit'],
        'averageDailyRate': json['average_daily_rate'],
        'averageOccupancy': json['average_occupancy'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}

export function MarketToJSON(json: any): Market {
    return MarketToJSONTyped(json, false);
}

export function MarketToJSONTyped(value?: Market | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'country': value['country'],
        'country_code': value['countryCode'],
        'state': value['state'],
        'city': value['city'],
        'total_properties': value['totalProperties'],
        'average_profit': value['averageProfit'],
        'average_daily_rate': value['averageDailyRate'],
        'average_occupancy': value['averageOccupancy'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
    };
}

