/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthTokensResponse
 */
export interface AuthTokensResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthTokensResponse
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokensResponse
     */
    refreshToken: string;
}

/**
 * Check if a given object implements the AuthTokensResponse interface.
 */
export function instanceOfAuthTokensResponse(value: object): value is AuthTokensResponse {
    if (!('accessToken' in value) || value['accessToken'] === undefined) return false;
    if (!('refreshToken' in value) || value['refreshToken'] === undefined) return false;
    return true;
}

export function AuthTokensResponseFromJSON(json: any): AuthTokensResponse {
    return AuthTokensResponseFromJSONTyped(json, false);
}

export function AuthTokensResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthTokensResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'accessToken': json['access_token'],
        'refreshToken': json['refresh_token'],
    };
}

export function AuthTokensResponseToJSON(json: any): AuthTokensResponse {
    return AuthTokensResponseToJSONTyped(json, false);
}

export function AuthTokensResponseToJSONTyped(value?: AuthTokensResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'access_token': value['accessToken'],
        'refresh_token': value['refreshToken'],
    };
}

