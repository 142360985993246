import React, {ReactNode, useEffect, useState} from 'react';
import Header from '../components/Header/index';
import Sidebar from '../components/Sidebar/index';
import useLocalStorage from "../hooks/useLocalStorage.tsx";

const DefaultLayout: React.FC<{ children: ReactNode }> = ({children}) => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [storedSidebarExpanded, setStoredSidebarExpanded] = useLocalStorage<string | null>('sidebar-expanded', null);

    const isSidebarOpen = () => {
        return storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    };

    const handleToggleSidebar = () => {
        setStoredSidebarExpanded(`${!isSidebarOpen()}`);
    };

    useEffect(() => {
        const isOpen = isSidebarOpen()
        if (isOpen) {
            document.querySelector('body')?.classList.add('sidebar-expanded');
        } else {
            document.querySelector('body')?.classList.remove('sidebar-expanded');
        }

        setSidebarOpen(isOpen);
    }, [storedSidebarExpanded])


    return (
        <div className="dark:bg-boxdark-2 dark:text-bodydark">
            <div className="flex h-screen overflow-hidden">

                <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={handleToggleSidebar}/>

                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    <Header sidebarOpen={sidebarOpen} toggleSidebar={handleToggleSidebar}/>

                    <main>
                        <div className="mx-auto max-w-screen-2xl py-4 px-4">
                            {children}
                        </div>
                    </main>

                </div>
            </div>
        </div>
    );
};

export default DefaultLayout;
