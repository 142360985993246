import {formatInTimeZone, toZonedTime} from 'date-fns-tz';
import {
    ArchiveRestore,
    Building2,
    CalendarDays,
    ChevronDown,
    ChevronUp,
    CreditCard,
    DollarSign,
    Dumbbell,
    Flame,
    Heart,
    Home,
    KeySquare,
    MapPin,
    Recycle,
    Tag,
    Waves
} from 'lucide-react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FloorPlan, Property, PropertyOfficeHours} from '../../clients/vercasa';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb.tsx';
import FloorPlanROIChart from '../../components/Charts/FloorPlanROI.tsx';
import PropertyDetailActionDropdown from '../../components/Dropdowns/PropertyDetailDropdownActions.tsx';
import AddToListModal from '../../components/Modals/AddToPropertyListModal.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import FloorPlans from '../../components/Property/FloorPlans.tsx';
import PropertyImageGallery from '../../components/Property/ImageGallery.tsx';
import {useColorMode} from '../../contexts/ColorModeContext.tsx';
import {usePropertiesAPI} from '../../hooks/useVerCasaAPI.tsx';
import DefaultLayout from '../../layout/DefaultLayout.tsx';
import {formatCurrency} from '../../utils/currencyFormatter.ts';
import {
    getAverageOccupancyFromFloorPlan,
    getHighestAverageDailyRateFromFloorPlan,
    getHighestProfitFromFloorPlan,
    getMostProfitableFloorPlan
} from '../../utils/propertyUtils.ts';


const PropertyDetail: React.FC = () => {
    const {id} = useParams<{ id: string }>();

    const {getProperty, favoriteProperty} = usePropertiesAPI();
    const {colorMode} = useColorMode();

    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useRef<mapboxgl.Map | null>(null);
    const roiChartRef = useRef<HTMLDivElement>(null);

    const [property, setProperty] = useState<Property | null>(null);
    const [loading, setLoading] = useState(true);
    const [isAddToListModalOpen, setIsAddToListModalOpen] = useState(false);
    const [selectedFloorPlan, setSelectedFloorPlan] = useState<FloorPlan | null>(null);
    const [isFavorite, setIsFavorite] = useState(false);

    const [officeHoursExpanded, setOfficeHoursExpanded] = useState(false);


    useEffect(() => {
        const fetchProperty = async () => {
            try {
                const res = await getProperty(id);
                setProperty(res);
            } catch (error) {
                console.error('Error fetching property:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProperty();
    }, [id]);

    useEffect(() => {
        if (property) {
            setIsFavorite(property.favorite);

            const mostProfitableFloorPlan = getMostProfitableFloorPlan(property);
            setSelectedFloorPlan(mostProfitableFloorPlan);
        }

        if (property && !map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/standard?optimize=true',
                center: [property.address.longitude, property.address.latitude],
                zoom: 16.5,
                pitch: 62,
                bearing: -20
            });

            map.current.once('style.load', setMapConfigProperties);
            map.current.once('load', () => {
                map.current.addControl(new mapboxgl.NavigationControl());

                new mapboxgl.Marker({color: '#3C50E0'})
                    .setLngLat([property.address.longitude, property.address.latitude])
                    .addTo(map.current);
            })
        }
    }, [property]);

    useEffect(() => {
        if (map.current) {

            if (map.current.isStyleLoaded()) {
                setMapConfigProperties();
            }

            return () => {
                map.current.off('style.load', setMapConfigProperties);
            };
        }
    }, [colorMode]);


    const renderAmenities = (amenities: string[]) => {
        const amenityIcons = {
            Pool: <Waves size={32}/>,
            'Fitness Center': <Dumbbell size={32}/>,
            Elevator: <ArchiveRestore size={32}/>,
            Clubhouse: <Home size={32}/>,
            'Controlled Access': <KeySquare size={32}/>,
            Recycling: <Recycle size={32}/>,
            'Business Center': <Building2 size={32}/>,
            Grill: <Flame size={32}/>,
        };

        return (
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {amenities.map((amenity, index) => (
                    <div key={index} className="flex items-center">
                        {amenityIcons[amenity] || <Home/>}
                        <span className="ml-2">{amenity}</span>
                    </div>
                ))}
            </div>
        );
    };


    const convertToLocalTime = (utcDate: Date): string => {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localDate = toZonedTime(utcDate, userTimeZone);
        return formatInTimeZone(localDate, userTimeZone, 'h:mm a');
    };

    const renderTodayOfficeHours = (officeHours: Array<PropertyOfficeHours>) => {
        const today = new Date().toLocaleString('en-us', {weekday: 'long'});
        const todayHours = officeHours.find(h => h.day === today);

        if (!todayHours) return null;

        return (
            <p className="mb-2">
                Today: {convertToLocalTime(new Date(todayHours.start))} - {convertToLocalTime(new Date(todayHours.end))}
            </p>
        );
    };

    const renderOfficeHours = (officeHours: Array<PropertyOfficeHours>) => {
        const today = new Date().toLocaleString('en-us', {weekday: 'long'});
        const sortedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        return (
            <div className="grid grid-cols-1">
                {sortedDays.map((day) => {
                    const hours = officeHours.find(h => h.day === day);
                    const isToday = day === today;

                    if (!hours) return null;

                    return (
                        <div
                            key={day}
                            className={`flex justify-between p-2 rounded ${isToday ? 'bg-primary/10 font-semibold' : ''}`}
                        >
                            <span className={`${isToday ? 'text-primary' : 'font-medium'}`}>
                                {day}:
                            </span>
                            <span>
                                {convertToLocalTime(new Date(hours.start))} - {convertToLocalTime(new Date(hours.end))}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    };


    const setMapConfigProperties = () => {
        map.current.setConfigProperty('basemap', 'lightPreset', colorMode === 'dark' ? 'dusk' : 'day');
        map.current.setConfigProperty('basemap', 'showPlaceLabels', true);
        map.current.setConfigProperty('basemap', 'showPointOfInterestLabels', true);
        map.current.setConfigProperty('basemap', 'showRoadLabels', true);
    }

    const handleSeeROI = (floorPlan: FloorPlan) => {
        setSelectedFloorPlan(floorPlan);
        roiChartRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const handleFavoriteToggle = async () => {
        if (!property) return;
        try {
            await favoriteProperty(property.id, !property.favorite);
            setIsFavorite(!isFavorite);
            setProperty({...property, favorite: !isFavorite});
        } catch (error) {
            console.error('Error toggling favorite:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!property) {
        return <div>Property not found</div>;
    }

    const mostProfitableFloorPlan: FloorPlan = getMostProfitableFloorPlan(property);
    const highestProfit = getHighestProfitFromFloorPlan(mostProfitableFloorPlan);
    const highestAverageDailyRate = getHighestAverageDailyRateFromFloorPlan(mostProfitableFloorPlan);
    const highestOccupancy = getAverageOccupancyFromFloorPlan(mostProfitableFloorPlan);


    return (
        <DefaultLayout>
            <Breadcrumb/>
            <PageTitle title='Property'/>

            <div className="grid grid-cols-12 gap-4 md:gap-6 2xl:gap-7.5">

                <div className="col-span-12">

                    <div
                        className="mb-5 p-6 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">

                        <div className="flex justify-between">

                            <div>
                                <h2 className="text-2xl font-bold text-black dark:text-white mb-4">
                                    {property.name}
                                </h2>
                                {property.company && (
                                    <p className="text-lg text-gray-600 dark:text-gray-400 mb-2">
                                        {property.company.name}
                                    </p>
                                )}
                                <div className="flex items-center gap-2 mb-2">
                                    <MapPin className="text-primary" size={18}/>
                                    <p className="text-gray-700 dark:text-gray-300">
                                        {property.address.formatted}
                                    </p>
                                </div>
                                <p className="text-gray-700 dark:text-gray-300 mb-2">
                                    <span className="font-semibold">Type:</span>
                                    {property.type}
                                </p>
                            </div>

                            <div className="md:w-64 bg-gray-50 dark:bg-gray-800">
                                <PropertyDetailActionDropdown property={property} onFavorite={handleFavoriteToggle}
                                                              onAddToList={() => setIsAddToListModalOpen(true)}/>
                            </div>
                        </div>
                    </div>

                    <div className="relative">
                        <PropertyImageGallery images={property.images}/>

                        <button
                            onClick={handleFavoriteToggle}
                            className="absolute -top-4 -left-4 p-2 rounded-full bg-white bg-opacity-70 hover:bg-opacity-100 transition-all duration-300 z-10"
                        >
                            <Heart
                                size={28}
                                className={`${isFavorite ? 'text-danger fill-current' : 'text-gray-600'} transition-colors duration-300`}
                            />
                        </button>
                    </div>


                    {/* Property Overview */}
                    <div
                        className="mb-5 p-6 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
                            <div
                                className="flex justify-between border-b border-r border-stroke dark:border-strokedark xl:border-b-0">
                                <div className="flex items-center gap-5.5 mx-auto">
                                    <div
                                        className="flex h-15 w-14.5 items-center justify-center rounded-lg bg-warning/[0.08]">
                                        <CreditCard/>
                                    </div>

                                    <div>
                                        <p className="text-lg font-medium text-warning">Monthly Rent</p>
                                        <span
                                            className="font-bold">{formatCurrency(mostProfitableFloorPlan.listedPrice)}</span>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="flex justify-between border-b border-r border-stroke dark:border-strokedark xl:border-b-0">
                                <div className="flex items-center gap-5.5 mx-auto">
                                    <div
                                        className="flex h-15 w-14.5 items-center justify-center rounded-lg bg-secondary/[0.08]">
                                        <CalendarDays/>
                                    </div>

                                    <div>
                                        <p className="text-lg font-medium text-secondary">Occupancy</p>
                                        <span className="font-bold">{highestOccupancy}%</span>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="flex justify-between border-b border-r border-stroke dark:border-strokedark sm:border-b-0">
                                <div className="flex items-center gap-5.5 mx-auto">
                                    <div
                                        className="flex h-15 w-14.5 items-center justify-center rounded-lg bg-[#2F80ED]/[0.08]">
                                        <Tag/>
                                    </div>

                                    <div>
                                        <p className="text-lg font-medium text-[#2F80ED]">Avg. Daily Rate</p>
                                        <span className="font-bold">{formatCurrency(highestAverageDailyRate)}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-between">
                                <div className="flex items-center gap-5.5 mx-auto">
                                    <div
                                        className="flex h-15 w-14.5 items-center justify-center rounded-lg bg-success/[0.08]">
                                        <DollarSign/>
                                    </div>

                                    <div>
                                        <p className="text-lg font-medium text-success">Profit</p>
                                        <span className="font-bold">{formatCurrency(highestProfit)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* New Amenities Section */}
                    {property.amenities && property.amenities.length > 0 && (
                        <div
                            className="mb-5 p-6 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">

                            <h4 className="text-xl font-semibold text-black dark:text-white mb-4">
                                Amenities
                            </h4>

                            {renderAmenities(property.amenities)}
                        </div>
                    )}


                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {property.leaseTerms && property.leaseTerms.length > 0 && (
                            <div
                                className="mb-5 p-6 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                                <h4 className="text-xl font-semibold text-black dark:text-white mb-4">
                                    Lease Terms
                                </h4>
                                <div className="flex flex-wrap gap-2">
                                    {property.leaseTerms.map((term, index) => (
                                        <div className='flex' key={index}>
                                            <span
                                                className="inline-flex rounded-full border border-primary py-1 px-3 text-sm font-medium text-primary hover:opacity-80 dark:text-white">
                                                {term} {term === 1 ? 'month' : 'months'}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        {property.officeHours && property.officeHours.length > 0 && (
                            <div
                                className="mb-5 p-6 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                                <div className="flex justify-between items-center mb-4">
                                    <h4 className="text-xl font-semibold text-black dark:text-white">
                                        Office Hours
                                    </h4>
                                    <button
                                        onClick={() => setOfficeHoursExpanded(!officeHoursExpanded)}
                                        className="text-primary"
                                    >
                                        {officeHoursExpanded ? <ChevronUp size={20}/> : <ChevronDown size={20}/>}
                                    </button>
                                </div>
                                {renderTodayOfficeHours(property.officeHours)}
                                {officeHoursExpanded && renderOfficeHours(property.officeHours)}
                            </div>
                        )}
                    </div>


                    <FloorPlans property={property} onSeeROI={handleSeeROI}/>

                    {selectedFloorPlan && (
                        <div ref={roiChartRef}
                             className="mb-5 p-6 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                            <div className="flex justify-between items-center mb-4">

                                <h4 className="text-xl font-semibold text-black dark:text-white">
                                    Return on Investment
                                </h4>

                                <select
                                    className="relative z-20 inline-flex bg-transparent rounded border-[1.5px] bg-transparent border-primary py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                    value={selectedFloorPlan.id}
                                    onChange={(e) => {
                                        const newSelectedFloorPlan = property.floorPlans.find(fp => fp.id == e.target.value);
                                        if (newSelectedFloorPlan) {
                                            setSelectedFloorPlan(newSelectedFloorPlan);
                                        }
                                    }}
                                >
                                    {property.floorPlans.map((fp, index) => (
                                        <option key={index} value={fp.id}>
                                            {fp.bedrooms} Bedroom / {fp.bathrooms} Bathroom
                                        </option>
                                    ))}
                                </select>


                            </div>
                            <FloorPlanROIChart floorPlan={selectedFloorPlan}/>
                        </div>
                    )}

                    {/* Property Location */}
                    <div
                        className="p-6 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                        <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                            <MapPin className="text-primary inline"/> Property Location
                        </h4>
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center gap-3">

                            </div>
                            <div ref={mapContainer} className="h-[500px] w-full"/>
                        </div>
                    </div>
                </div>
            </div>

            <AddToListModal
                isOpen={isAddToListModalOpen}
                onClose={() => setIsAddToListModalOpen(false)}
                propertyId={property.id}
            />
        </DefaultLayout>
    );
};

export default PropertyDetail;