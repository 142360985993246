/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  PaginatedPropertyListsResponse,
  PropertyListAddPropertiesRequest,
  PropertyListCreateRequest,
  PropertyListResponse,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PaginatedPropertyListsResponseFromJSON,
    PaginatedPropertyListsResponseToJSON,
    PropertyListAddPropertiesRequestFromJSON,
    PropertyListAddPropertiesRequestToJSON,
    PropertyListCreateRequestFromJSON,
    PropertyListCreateRequestToJSON,
    PropertyListResponseFromJSON,
    PropertyListResponseToJSON,
} from '../models/index';

export interface PropertyListAddPropertiesOperationRequest {
    id: string;
    propertyListAddPropertiesRequest: PropertyListAddPropertiesRequest;
}

export interface PropertyListDeleteRequest {
    id: string;
}

export interface PropertyListsRequest {
    limit?: number;
    offset?: number;
}

export interface PropertyListsCreateRequest {
    propertyListCreateRequest: PropertyListCreateRequest;
}

/**
 * 
 */
export class PropertyListsApi extends runtime.BaseAPI {

    /**
     * Adds properties to a property list
     * Adds properties to a property list
     */
    async propertyListAddPropertiesRaw(requestParameters: PropertyListAddPropertiesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PropertyListResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling propertyListAddProperties().'
            );
        }

        if (requestParameters['propertyListAddPropertiesRequest'] == null) {
            throw new runtime.RequiredError(
                'propertyListAddPropertiesRequest',
                'Required parameter "propertyListAddPropertiesRequest" was null or undefined when calling propertyListAddProperties().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-lists/{id}/add-properties`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyListAddPropertiesRequestToJSON(requestParameters['propertyListAddPropertiesRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyListResponseFromJSON(jsonValue));
    }

    /**
     * Adds properties to a property list
     * Adds properties to a property list
     */
    async propertyListAddProperties(requestParameters: PropertyListAddPropertiesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PropertyListResponse> {
        const response = await this.propertyListAddPropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a property list
     * Deletes a property list
     */
    async propertyListDeleteRaw(requestParameters: PropertyListDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling propertyListDelete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-lists/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a property list
     * Deletes a property list
     */
    async propertyListDelete(requestParameters: PropertyListDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.propertyListDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Gets all of the property lists for the user
     * Gets all of the property lists for the user
     */
    async propertyListsRaw(requestParameters: PropertyListsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPropertyListsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-lists`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPropertyListsResponseFromJSON(jsonValue));
    }

    /**
     * Gets all of the property lists for the user
     * Gets all of the property lists for the user
     */
    async propertyLists(requestParameters: PropertyListsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPropertyListsResponse> {
        const response = await this.propertyListsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new property list
     * Creates a new property list
     */
    async propertyListsCreateRaw(requestParameters: PropertyListsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PropertyListResponse>> {
        if (requestParameters['propertyListCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'propertyListCreateRequest',
                'Required parameter "propertyListCreateRequest" was null or undefined when calling propertyListsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-lists`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PropertyListCreateRequestToJSON(requestParameters['propertyListCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyListResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new property list
     * Creates a new property list
     */
    async propertyListsCreate(requestParameters: PropertyListsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PropertyListResponse> {
        const response = await this.propertyListsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
