/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertyPatchRequest
 */
export interface PropertyPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof PropertyPatchRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyPatchRequest
     */
    favorite?: boolean;
}

/**
 * Check if a given object implements the PropertyPatchRequest interface.
 */
export function instanceOfPropertyPatchRequest(value: object): value is PropertyPatchRequest {
    return true;
}

export function PropertyPatchRequestFromJSON(json: any): PropertyPatchRequest {
    return PropertyPatchRequestFromJSONTyped(json, false);
}

export function PropertyPatchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyPatchRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'favorite': json['favorite'] == null ? undefined : json['favorite'],
    };
}

export function PropertyPatchRequestToJSON(json: any): PropertyPatchRequest {
    return PropertyPatchRequestToJSONTyped(json, false);
}

export function PropertyPatchRequestToJSONTyped(value?: PropertyPatchRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'favorite': value['favorite'],
    };
}

