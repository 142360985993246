import React, {useEffect, useState} from 'react';
import DefaultLayout from '../../layout/DefaultLayout.tsx';
import {useTeamsAPI} from "../../hooks/useVerCasaAPI.tsx";
import {MyTeam} from "../../clients/vercasa";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import userThree from '../../images/user/user-01.png';


const Teams: React.FC = () => {
    const {getMyTeam} = useTeamsAPI();
    const [team, setTeam] = useState<MyTeam>(null);

    const fetchTeam = async () => {
        try {
            const team: MyTeam = await getMyTeam();
            setTeam(team);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchTeam();
    }, [])

    return (
        <DefaultLayout>
            <div className="flex flex-col gap-7.5">

                <div
                    className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">

                    <div className="border-b border-stroke px-4 py-4 dark:border-strokedark sm:px-6 xl:px-9">
                        <h3 className="font-medium text-black dark:text-white text-center">{team?.name || 'Your Team'}</h3>
                    </div>

                    <div className="p-4 sm:p-6 xl:p-9">
                        {team && (
                            <div className="mx-auto w-full max-w-[1170px]">
                                <div className="grid grid-cols-1 gap-7.5 sm:grid-cols-2 xl:grid-cols-4">
                                    {team.members.map((user, index) => (
                                        <div
                                            key={index}
                                            className="group rounded-[10px] border border-stroke px-4 pb-10 pt-12 dark:border-strokedark"
                                        >
                                            <div className="relative z-1 mx-auto h-30 w-full max-w-30 rounded-full">
                                                <LazyLoadImage
                                                    src={user?.photo || userThree}
                                                    alt="team member photo"
                                                    className="rounded-full"
                                                />
                                            </div>

                                            <div className="my-5 text-center">
                                                <h4 className="mb-0.5 text-lg font-bold text-black dark:text-white">
                                                    {user?.firstName || 'Your'} {user?.lastName || 'Name'}
                                                </h4>
                                                {/*<p className="text-xs font-medium">{item.position}</p>*/}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default Teams;
