import React, {useCallback, useEffect, useRef, useState} from 'react';
import Card from '../../components/Property/Card';
import MarketBreadcrumb from '../../components/Property/MarketBreadcrumb';
import {useSearchParams} from 'react-router-dom';
import Map, {MapRef, Marker, NavigationControl, Popup} from 'react-map-gl';
import {Market, Markets, MarketsRequest, Property} from "../../clients/vercasa";
import {useMarketsAPI, usePropertiesAPI} from "../../hooks/useVerCasaAPI";
import MapLayout from '../../layout/MapLayout';
import MarketCard from "../../components/Property/MarketCard.tsx";
import {MapPin} from 'lucide-react';
import {useColorMode} from "../../contexts/ColorModeContext.tsx";
import Supercluster from 'supercluster';

// Define zoom levels for different market types
const ZOOM_LEVELS = {
    WORLD: 1,
    COUNTRY: 4,
    STATE: 6,
    CITY: 12
};

const PropertyMarkets: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [markets, setMarkets] = useState<Markets>([]);
    const [selectedMarket, setSelectedMarket] = useState<Market | null>(null);
    const [hoveredMarket, setHoveredMarket] = useState<Market | null>(null);
    const [clusters, setClusters] = useState<any[]>([]);
    const [properties, setProperties] = useState<Property[]>([]);

    const {getMarkets} = useMarketsAPI();
    const {getProperties} = usePropertiesAPI();
    const {colorMode} = useColorMode();

    const mapRef = useRef<MapRef>(null);
    const superclusterRef = useRef<Supercluster>();

    // Viewport state with more precise initial values
    const [viewState, setViewState] = useState({
        latitude: 20,  // Better center point for world view
        longitude: 0,
        zoom: ZOOM_LEVELS.WORLD,
        padding: {top: 50, bottom: 50, left: 50, right: 50}
    });

    const getZoomLevel = (market: Market | null) => {
        if (!market) return ZOOM_LEVELS.WORLD;
        if (market.city) return ZOOM_LEVELS.CITY;
        if (market.state) return ZOOM_LEVELS.STATE;
        return ZOOM_LEVELS.COUNTRY;
    };


    // Update clusters when the viewport changes
    const updateClusters = () => {
        if (!superclusterRef.current || !mapRef.current) return;

        const map = mapRef.current.getMap();
        const bounds = map.getBounds().toArray().flat();
        const zoom = Math.floor(map.getZoom());

        const clusters = superclusterRef.current.getClusters(bounds, zoom);
        setClusters(clusters);
    };

    const flyToMarket = useCallback((market: Market | null) => {
        if (!mapRef.current) return;

        // Use requestAnimationFrame to ensure smooth transitions
        requestAnimationFrame(() => {
            mapRef.current?.flyTo({
                center: market
                    ? [market.longitude, market.latitude]
                    : [0, 20],
                zoom: getZoomLevel(market),
                padding: {top: 50, bottom: 50, left: 50, right: 50},
                essential: true,
                duration: 1000 // Consistent animation duration
            });
        });
    }, []);


    // Single source of truth - derive filters from searchParams
    const getFiltersFromParams = useCallback((): MarketsRequest => ({
        countryCode: searchParams.get('country_code') || null,
        state: searchParams.get('state') || null,
        city: searchParams.get('city') || null,
        limit: parseInt(searchParams.get('limit') || '24'),
        offset: parseInt(searchParams.get('offset') || '0')
    }), [searchParams]);

    // Handlers
    const handleMarketClick = (market: Market) => {
        const newParams = new URLSearchParams(searchParams);

        // Clear existing params
        newParams.delete('country_code');
        newParams.delete('state');
        newParams.delete('city');
        newParams.delete('limit');
        newParams.delete('offset');

        if (market.country) newParams.set('country_code', market.countryCode);
        if (market.state) newParams.set('state', market.state);
        if (market.city) newParams.set('city', market.city);

        // Set the selected market before updating URL params
        setSelectedMarket(market);
        setSearchParams(newParams);
    };

    const handleClearFilters = (level?: 'country' | 'state' | 'city') => {
        const newParams = new URLSearchParams(searchParams);

        if (!level) {
            // Clear all filters
            setMarkets([]);
            setSearchParams({});
            setSelectedMarket(null);
            setHoveredMarket(null);

            requestAnimationFrame(() => {
                setViewState({
                    latitude: 20,
                    longitude: 0,
                    zoom: ZOOM_LEVELS.WORLD,
                    padding: {top: 50, bottom: 50, left: 50, right: 50}
                });
            });
            return;
        }

        // Handle hierarchical clearing
        switch (level) {
            case 'country':
                newParams.delete('country_code');
                newParams.delete('state');
                newParams.delete('city');
                // Reset to world view when clearing country
                requestAnimationFrame(() => {
                    setViewState({
                        latitude: 20,
                        longitude: 0,
                        zoom: ZOOM_LEVELS.WORLD,
                        padding: {top: 50, bottom: 50, left: 50, right: 50}
                    });
                });
                break;
            case 'state':
                newParams.delete('state');
                newParams.delete('city');
                break;
            case 'city':
                newParams.delete('city');
                break;
        }

        // Find the most specific matching market based on remaining filters
        const remainingCountryCode = newParams.get('country_code');
        const remainingState = newParams.get('state');

        let matchingMarket = null;
        if (markets.length > 0) {
            if (remainingState) {
                // Try to find market matching country and state
                matchingMarket = markets.find(market =>
                    market.countryCode === remainingCountryCode &&
                    market.state === remainingState &&
                    !market.city
                );
            } else if (remainingCountryCode) {
                // Try to find market matching just country
                matchingMarket = markets.find(market =>
                    market.countryCode === remainingCountryCode &&
                    !market.state &&
                    !market.city
                );
            }
        }

        setSearchParams(newParams);
        setSelectedMarket(matchingMarket);
        setHoveredMarket(null);
    };

    const handleMarkerClick = (market: Market, event?: React.MouseEvent) => {
        event?.stopPropagation(); // Prevent event bubbling
        handleMarketClick(market);
    };

    const handleMarkerHover = (market: Market | null) => {
        setHoveredMarket(market);
    };


    // Render cluster or individual marker
    const renderMarker = (cluster: any) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const {cluster: isCluster, point_count: pointCount, marketId} = cluster.properties;

        if (isCluster) {
            return (
                <Marker
                    key={`cluster-${cluster.id}`}
                    latitude={latitude}
                    longitude={longitude}
                >
                    <div
                        className={`
                            flex items-center justify-center
                            rounded-full bg-primary dark:bg-white
                            text-white dark:text-primary
                            w-10 h-10 font-semibold
                            transition-all duration-200
                            hover:scale-110 cursor-pointer
                            shadow-md
                        `}
                        onClick={() => {
                            const currentZoom = mapRef.current?.getZoom() ?? 0;
                            const expansionZoom = Math.min(
                                Math.max(
                                    superclusterRef.current?.getClusterExpansionZoom(cluster.id) ?? 0,
                                    currentZoom + 2
                                ),
                                20
                            );

                            mapRef.current?.flyTo({
                                center: [longitude, latitude],
                                zoom: expansionZoom,
                                essential: true,
                                duration: 1000
                            });
                        }}
                    >
                        {pointCount}
                    </div>
                </Marker>
            );
        }

        const market = cluster.properties.market;

        return (
            <div
                key={marketId}
                onMouseEnter={() => handleMarkerHover(market)}
                onMouseLeave={() => handleMarkerHover(null)}
            >
                <Marker
                    latitude={latitude}
                    longitude={longitude}
                >
                    <div
                        className="cursor-pointer transform transition-all duration-200"
                        onClick={() => handleMarkerClick(market)}
                    >
                        <MapPin
                            className={`w-7 h-7 transition-all duration-200 hover:scale-125 text-primary dark:text-white`}/>
                    </div>
                </Marker>
                {hoveredMarket?.countryCode === market.countryCode &&
                    hoveredMarket?.state === market.state &&
                    hoveredMarket?.city === market.city && (
                        <Popup
                            latitude={latitude}
                            longitude={longitude}
                            closeButton={false}
                            closeOnClick={false}
                            anchor="bottom"
                            className="z-50"
                        >
                            <MarketCard
                                market={market}
                                onClick={() => handleMarketClick(market)}
                                isSelected={selectedMarket?.countryCode === market.countryCode &&
                                    selectedMarket?.state === market.state &&
                                    selectedMarket?.city === market.city}
                            />
                        </Popup>
                    )}
            </div>
        );
    };

    const currentFilters = getFiltersFromParams();

    // Fetch markets when searchParams change
    useEffect(() => {
        const fetchAndSetMarket = async () => {
            try {
                const currentFilters = getFiltersFromParams();
                const res = await getMarkets(currentFilters);

                if (res.data.length > 0) {
                    setMarkets(res.data);
                    const matchingMarket = res.data.find(mkt => (mkt.countryCode === currentFilters.countryCode && mkt.state === currentFilters.state && mkt.city === currentFilters.city))

                    if (matchingMarket) {
                        setSelectedMarket(matchingMarket);
                    }
                }
            } catch (err) {
                console.error('Error fetching markets:', err);
            }
        };

        fetchAndSetMarket();
    }, [searchParams]);

    // Initialize map with custom styles
    useEffect(() => {
        if (!mapRef.current) return;

        const map = mapRef.current.getMap();

        // Set map configuration properties
        map.setConfigProperty('basemap', 'lightPreset', colorMode === 'dark' ? 'dusk' : 'day');
        map.setConfigProperty('basemap', 'showPlaceLabels', true);
        map.setConfigProperty('basemap', 'showPointOfInterestLabels', true);
        map.setConfigProperty('basemap', 'showRoadLabels', true);
    }, [colorMode, mapRef.current]);


    // Handle camera updates and property fetching when selected market changes
    useEffect(() => {
        if (selectedMarket) {
            // Handle camera movement
            const timer = setTimeout(() => {
                flyToMarket(selectedMarket);
            }, 100);

            // Fetch properties if we have a city-level market
            if (selectedMarket.countryCode && selectedMarket.state && selectedMarket.city) {
                const fetchProperties = async () => {
                    try {
                        const res = await getProperties({
                            state: selectedMarket.state,
                            city: selectedMarket.city,
                            limit: 100,  // Adjust this value as needed
                            offset: 0
                        });
                        setProperties(res.data);
                    } catch (error) {
                        setProperties([]);
                    }
                };
                fetchProperties();
            } else {
                // Clear properties if not at city level
                setProperties([]);
            }

            return () => clearTimeout(timer);
        } else {
            setProperties([]);
        }
    }, [selectedMarket, flyToMarket, getProperties]);


    // Initialize Supercluster
    useEffect(() => {
        if (markets.length === 0) return;

        const points = markets.map(market => ({
            type: 'Feature',
            properties: {
                cluster: false,
                marketId: `${market.countryCode}-${market.state}-${market.city}`,
                market: market
            },
            geometry: {
                type: 'Point',
                coordinates: [market.longitude, market.latitude]
            }
        }));

        superclusterRef.current = new Supercluster({
            radius: 40,
            maxZoom: 16
        });

        superclusterRef.current.load(points);
        updateClusters();
    }, [markets]);

    return (
        <MapLayout>
            <div className="flex h-[calc(100vh-64px)]">
                {/* Left side - Map */}
                <div className="flex-1 relative">
                    <Map
                        ref={mapRef}
                        {...viewState}
                        onMove={evt => {
                            setViewState(evt.viewState);
                            updateClusters();
                        }}
                        onZoom={() => {
                            updateClusters();
                        }}
                        mapStyle="mapbox://styles/mapbox/standard?optimize=true"
                        initialViewState={viewState}
                    >
                        <NavigationControl position="top-right"/>
                        {clusters.map(renderMarker)}

                    </Map>
                </div>

                {/* Right side - Market cards */}
                <div className="w-[600px] 2xl:w-[1200px] p-4 md:p-6 2xl:p-10 overflow-y-auto">
                    <div className="space-y-4">
                        <div>
                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-white mb-2">
                                Property Markets
                            </h2>
                            <p className="text-gray-500 dark:text-gray-400">
                                Explore property markets and their performance metrics
                            </p>
                        </div>

                        <MarketBreadcrumb
                            countryCode={currentFilters.countryCode}
                            state={currentFilters.state}
                            city={currentFilters.city}
                            onClearFilter={handleClearFilters}
                        />
                    </div>

                    {currentFilters.city && properties.length > 0 ? (
                        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                            {properties.map((property) => (
                                <Card
                                    key={property.id}
                                    property={property}
                                    showDetails={true}
                                    onClick={() => {
                                    }}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                            {markets.filter(market =>
                                !(selectedMarket?.countryCode === market.countryCode &&
                                    selectedMarket?.state === market.state &&
                                    selectedMarket?.city === market.city)
                            ).map((market, index) => (
                                <MarketCard
                                    key={`${market.countryCode}-${market.state}-${market.city}-${index}`}
                                    market={market}
                                    onClick={() => handleMarketClick(market)}
                                    isSelected={selectedMarket === market}
                                    onMouseEnter={() => handleMarkerHover(market)}
                                    onMouseLeave={() => handleMarkerHover(null)}
                                />
                            ))}
                        </div>
                    )}
                </div>


            </div>
        </MapLayout>
    );

};

export default PropertyMarkets;
