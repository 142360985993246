/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateRequest
     */
    password: string;
}

/**
 * Check if a given object implements the UserCreateRequest interface.
 */
export function instanceOfUserCreateRequest(value: object): value is UserCreateRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function UserCreateRequestFromJSON(json: any): UserCreateRequest {
    return UserCreateRequestFromJSONTyped(json, false);
}

export function UserCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function UserCreateRequestToJSON(json: any): UserCreateRequest {
    return UserCreateRequestToJSONTyped(json, false);
}

export function UserCreateRequestToJSONTyped(value?: UserCreateRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'password': value['password'],
    };
}

