import React from 'react';
import {Building2, CalendarDays} from 'lucide-react';
import {formatCurrency} from '../../utils/currencyFormatter';
import {getProfitColor} from '../../utils/propertyUtils';
import {Market} from '../../clients/vercasa';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


interface MarketCardProps {
    market: Market;
    onClick: () => void;
    isSelected?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

const MarketCard: React.FC<MarketCardProps> = ({
                                                   market,
                                                   onClick,
                                                   onMouseEnter,
                                                   onMouseLeave
                                               }) => {
    const COUNTRY_PLACEHOLDER_IMAGES = {
        "US": "https://images.unsplash.com/photo-1485738422979-f5c462d49f74?q=80&w=624&auto=format"
    }

    const STATE_PLACEHOLDER_IMAGES = {
        "US": {
            "Alabama": "https://images.unsplash.com/photo-1677827635191-d130ed3e1639?q=80&w=624&auto=format",
            "Alaska": "https://images.unsplash.com/photo-1722109940444-cd67a5b2186a?q=80&w=624&auto=format",
            "Arizona": "https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?q=80&w=624&auto=format",
            "Arkansas": "https://images.unsplash.com/photo-1517694381106-c9ca8944559a?q=80&w=624&auto=format",
            "California": "https://images.unsplash.com/photo-1501594907352-04cda38ebc29?q=80&w=624&auto=format",
            "Colorado": "https://images.unsplash.com/photo-1503424886307-b090341d25d1?q=80&w=624&auto=format",
            "Connecticut": "https://images.unsplash.com/photo-1695733528425-70cfd3044fac?q=80&w=624&auto=format",
            "Delaware": "https://images.unsplash.com/photo-1592945615326-8f16af789a75?q=80&w=624&auto=format",
            "Florida": "https://images.unsplash.com/photo-1605723517503-3cadb5818a0c?q=80&w=624&auto=format",
            "Georgia": "https://images.unsplash.com/photo-1667826874961-b72a47c55360?q=80&w=624&auto=format",
            "Hawaii": "https://images.unsplash.com/photo-1505852679233-d9fd70aff56d?q=80&w=624&auto=format",
            "Idaho": "https://images.unsplash.com/photo-1579183587838-af83181b9a95?q=80&w=624&auto=format",
            "Illinois": "https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?q=80&w=624&auto=format",
            "Indiana": "https://images.unsplash.com/photo-1620184856187-5ff78f7da325?q=80&w=624&auto=format",
            "Iowa": "https://images.unsplash.com/photo-1558036117-15d82a90b9b1?q=80&w=624&auto=format",
            "Kansas": "https://images.unsplash.com/photo-1590433136906-733e707e7f25?q=80&w=624&auto=format",
            "Kentucky": "https://images.unsplash.com/photo-1609928789569-40dea3028057?q=80&w=624&auto=format",
            "Louisiana": "https://images.unsplash.com/photo-1717712786308-3fe2b01695d7?q=80&w=624&auto=format",
            "Maine": "https://images.unsplash.com/photo-1634670788411-be9aad50d637?q=80&w=624&auto=format",
            "Maryland": "https://images.unsplash.com/photo-1637178571052-db5a0c5fba93?q=80&w=624&auto=format",
            "Massachusetts": "https://images.unsplash.com/photo-1638717736905-10096c8f3a63?q=80&w=624&auto=format",
            "Michigan": "https://images.unsplash.com/photo-1730358887934-965d20f21187?q=80&w=624&auto=format",
            "Minnesota": "https://images.unsplash.com/photo-1567693221917-fefc994808ce?q=80&w=624&auto=format",
            "Mississippi": "https://images.unsplash.com/photo-1484271201072-03bfd82a56f6?q=80&w=624&auto=format",
            "Missouri": "https://images.unsplash.com/photo-1606972192702-8b1fcf170348?q=80&w=624&auto=format",
            "Montana": "https://images.unsplash.com/photo-1592249094259-0491c785da92?q=80&w=624&auto=format",
            "Nebraska": "https://images.unsplash.com/photo-1661357117363-6df13e644f79?q=80&w=624&auto=format",
            "Nevada": "https://images.unsplash.com/photo-1536942367753-3bdb71b7bb1c?q=80&w=624&auto=format",
            "New Hampshire": "https://images.unsplash.com/photo-1589938219129-3bff434f8c6a?q=80&w=624&auto=format",
            "New Jersey": "https://images.unsplash.com/photo-1589178941221-136be7711c99?q=80&w=624&auto=format",
            "New Mexico": "https://images.unsplash.com/photo-1508496869408-df596454df1d?q=80&w=624&auto=format",
            "New York": "https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?q=80&w=624&auto=format",
            "North Carolina": "https://images.unsplash.com/photo-1562762394-3acfba62a48e?q=80&w=624&auto=format",
            "North Dakota": "https://images.unsplash.com/photo-1603328198605-fd20581ccc4f?q=80&w=624&auto=format",
            "Ohio": "https://images.unsplash.com/photo-1501631454901-2fe040439c00?q=80&w=624&auto=format",
            "Oklahoma": "https://images.unsplash.com/photo-1600803299273-5a7bfb63cb04?q=80&w=624&auto=format",
            "Oregon": "https://images.unsplash.com/photo-1532921982367-0c75fe322456?q=80&w=624&auto=format",
            "Pennsylvania": "https://images.unsplash.com/photo-1535639019828-5afcac8f8a32?q=80&w=624&auto=format",
            "Rhode Island": "https://images.unsplash.com/photo-1695824476500-57920c7a1993?q=80&w=624&auto=format",
            "South Carolina": "https://images.unsplash.com/photo-1628458261688-09831bf32972?q=80&w=624&auto=format",
            "South Dakota": "https://images.unsplash.com/photo-1615088856732-1cfbca773f6a?q=80&w=624&auto=format",
            "Tennessee": "https://images.unsplash.com/photo-1545419913-775e3e82c7db?q=80&w=624&auto=format",
            "Texas": "https://images.unsplash.com/photo-1559118783-3188f9906c52?q=80&w=624&auto=format",
            "Utah": "https://images.unsplash.com/photo-1575408264798-b50b252663e6?q=80&w=624&auto=format",
            "Vermont": "https://images.unsplash.com/photo-1601189992152-6ef7ebb531a8?q=80&w=624&auto=format",
            "Virginia": "https://images.unsplash.com/photo-1624487214384-580499c9fdd6?q=80&w=624&auto=format",
            "Washington": "https://images.unsplash.com/photo-1668123508899-eca1b6c2a2c4?q=80&w=624&auto=format",
            "West Virginia": "https://images.unsplash.com/photo-1666214043271-c27f346d5a66?q=80&w=624&auto=format",
            "Wisconsin": "https://images.unsplash.com/photo-1660056252469-a1996f1f5080?q=80&w=624&auto=format",
            "Wyoming": "https://images.unsplash.com/photo-1645856497685-2ecffafeafe1?q=80&w=624&auto=format"
        }
    }

    const profitColor = getProfitColor(market.averageProfit);

    const getMarketName = (): string => {
        if (!market.city && !market.state) return market.country;
        if (market.state && !market.city) return `${market.state}, ${market.country}`;
        return `${market.city}, ${market.state}, ${market.country}`
    }

    const getBtnText = (): string => {
        if (!market.city && !market.state) return `View ${market.country} Markets`;
        if (market.state && !market.city) return `View ${market.state} Markets`;
        return 'View Properties';
    }

    const getImageUrl = (): string => {
        return !market.state
            ? COUNTRY_PLACEHOLDER_IMAGES[market.countryCode]
            : STATE_PLACEHOLDER_IMAGES[market.countryCode][market.state];
    }

    return (
        <div
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105 cursor-pointer dark:bg-boxdark"
        >
            <div className="relative">
                <LazyLoadImage
                    src={getImageUrl()}
                    alt={`${market.city || market.state || market.country}`}
                    wrapperProps={
                        {
                            style: {display: 'block'}
                        }
                    }
                    className="h-40 w-full object-cover"
                />
                <div className="absolute top-2 right-2 bg-white/90 dark:bg-boxdark/90 px-2 py-1 rounded text-xs">
                    {!market.state && !market.city ? 'Country' : !market.state ? 'City' : 'State'}
                </div>
            </div>

            <div className="p-4">
                <h3 className="text-center font-semibold text-gray-800 dark:text-white mb-3">
                    {getMarketName()}
                </h3>

                <div className="grid grid-cols-1 gap-2 mb-3">
                    <div className="flex font-medium items-center justify-center">
                        <Building2 size={18} className="mr-1 text-primary dark:text-white"/>
                        <span className="text-sm">{market.totalProperties} Properties
                        </span>
                    </div>
                </div>

                <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="text-center">
                            <div
                                className="flex items-center justify-center text-lg font-semibold dark:text-white mb-1">
                                <CalendarDays size={16} className="mr-1"/>{market.averageOccupancy}%
                            </div>
                            <div className="text-xs text-gray-600 dark:text-gray-400">
                                Occupancy
                            </div>
                        </div>

                        <div className="text-center border-x border-gray-200 dark:border-gray-700 px-2">
                            <div className="text-lg font-semibold dark:text-white mb-1">
                                {formatCurrency(market.averageDailyRate)}
                            </div>
                            <div className="text-xs text-gray-600 dark:text-gray-400">
                                Daily Rate
                            </div>
                        </div>

                        <div className="text-center">
                            <div className={`text-lg font-semibold ${profitColor} mb-1`}>
                                {formatCurrency(market.averageProfit)}
                            </div>
                            <div className="text-xs text-gray-600 dark:text-gray-400">
                                Annual Profit
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <button
                        onClick={onClick}
                        className="w-full bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark"
                    >
                        {getBtnText()}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MarketCard;
