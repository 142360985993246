import {Building2, CalendarDays, Heart} from 'lucide-react';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {FloorPlan, Property} from '../../clients/vercasa';
import {formatCurrency} from '../../utils/currencyFormatter';
import {
    getAverageOccupancyFromFloorPlan,
    getFloorPlanRange,
    getHighestAverageDailyRateFromFloorPlan,
    getHighestProfitFromFloorPlan,
    getMostProfitableFloorPlan,
    getProfitColor
} from '../../utils/propertyUtils';


import {usePropertiesAPI} from '../../hooks/useVerCasaAPI';

interface PropertyCardProps {
    property: Property;
    showDetails?: boolean;
    onClick?: () => void;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
                                                       property,
                                                       showDetails,
                                                       onClick
                                                   }) => {
    const navigate = useNavigate();
    const {favoriteProperty} = usePropertiesAPI();
    const [isFavorite, setIsFavorite] = useState<boolean>(property.favorite);

    const address = property.address;
    const mostProfitableFloorPlan: FloorPlan = getMostProfitableFloorPlan(property);
    const highestAverageDailyRate = getHighestAverageDailyRateFromFloorPlan(mostProfitableFloorPlan);
    const highestProfit = getHighestProfitFromFloorPlan(mostProfitableFloorPlan);
    const highestOccupancy = getAverageOccupancyFromFloorPlan(mostProfitableFloorPlan);
    const [minBeds, maxBeds] = getFloorPlanRange(property, 'bedrooms');
    const [minBaths, maxBaths] = getFloorPlanRange(property, 'bathrooms');
    const [minSqft, maxSqft] = getFloorPlanRange(property, 'sqft');
    const profitColor = getProfitColor(highestProfit);


    const handleFavoriteClick = async (e: React.MouseEvent) => {
        e.stopPropagation();

        try {
            await favoriteProperty(property.id, !isFavorite);
            setIsFavorite(prev => !prev);
        } catch (error) {
            console.error('Error favoriting property:', error);
        }
    };

    const handleViewDetails = (e: React.MouseEvent) => {
        e.stopPropagation();
        navigate(`/property/${property.id}`);
    };

    return (
        <div onClick={onClick || (() => navigate(`/properties/${property.id}`))}
             className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105 cursor-pointer dark:bg-boxdark">


            <div className="relative">
                <LazyLoadImage
                    src={property.images[0] || 'https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'}
                    alt={property.name || 'Property'}
                    effect="blur"
                    wrapperProps={
                        {
                            style: {display: 'block'}
                        }
                    }
                    className="h-40 w-full object-fill"
                />
                <div className="absolute top-2 left-2 bg-white/90 dark:bg-boxdark/90 px-2 py-1 rounded text-xs">
                    {property.type}
                </div>

                <button
                    onClick={handleFavoriteClick}
                    className="absolute top-0 right-0 m-2 p-1 rounded-full bg-white bg-opacity-50 hover:bg-opacity-100 transition-all duration-300"
                >
                    <Heart
                        size={24}
                        className={`${isFavorite ? 'text-danger fill-current' : 'text-gray-600'} transition-colors duration-300 hover:text-danger`}
                    />
                </button>

                {property.type === "Apartment" &&
                    <div className="absolute bottom-0 right-0 bg-black bg-opacity-50 text-white px-2 py-1 m-2 rounded">
                        {property.floorPlans.length} floor plans
                    </div>
                }
            </div>
            <div className="p-4">

                <h3 className="text-center font-semibold text-gray-800 dark:text-white mb-3">
                    {address.street}, {address.city}, {address.stateCode}
                </h3>

                {showDetails &&
                    <div className="grid grid-cols-1 gap-2 mb-3">
                        <div className="flex font-medium items-center justify-center">
                            <Building2 size={18} className="mr-1 text-primary dark:text-white"/>
                            <span className="text-sm">
                                {property.floorPlans.length} Floor Plans
                            </span>
                        </div>
                    </div>
                }

                <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="text-center">
                            <div
                                className="flex items-center justify-center text-lg font-semibold dark:text-white mb-1">
                                <CalendarDays size={16} className="mr-1"/>{highestOccupancy}%
                            </div>
                            <div className="text-xs text-gray-600 dark:text-gray-400">
                                Occupancy Rate
                            </div>
                        </div>

                        <div className="text-center border-x border-gray-200 dark:border-gray-700 px-2">
                            <div className="text-lg font-semibold dark:text-white mb-1">
                                {formatCurrency(highestAverageDailyRate)}
                            </div>
                            <div className="text-xs text-gray-600 dark:text-gray-400">
                                Daily Rate
                            </div>
                        </div>

                        <div className="text-center">
                            <div className={`text-lg font-semibold ${profitColor} mb-1`}>
                                {formatCurrency(highestProfit)}
                            </div>
                            <div className="text-xs text-gray-600 dark:text-gray-400">
                                Annual Profit
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <button onClick={handleViewDetails}
                            className="w-full bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark">
                        View Details
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
