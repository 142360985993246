/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RentalEstimate } from './RentalEstimate';
import {
    RentalEstimateFromJSON,
    RentalEstimateFromJSONTyped,
    RentalEstimateToJSON,
    RentalEstimateToJSONTyped,
} from './RentalEstimate';

/**
 * 
 * @export
 * @interface FloorPlan
 */
export interface FloorPlan {
    /**
     * 
     * @type {string}
     * @memberof FloorPlan
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    bedrooms: number;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    bathrooms: number;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    sqft: number;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    listedPrice: number;
    /**
     * 
     * @type {number}
     * @memberof FloorPlan
     */
    unitsAvailable: number;
    /**
     * 
     * @type {Array<RentalEstimate>}
     * @memberof FloorPlan
     */
    rentalEstimates: Array<RentalEstimate>;
}

/**
 * Check if a given object implements the FloorPlan interface.
 */
export function instanceOfFloorPlan(value: object): value is FloorPlan {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('bedrooms' in value) || value['bedrooms'] === undefined) return false;
    if (!('bathrooms' in value) || value['bathrooms'] === undefined) return false;
    if (!('sqft' in value) || value['sqft'] === undefined) return false;
    if (!('listedPrice' in value) || value['listedPrice'] === undefined) return false;
    if (!('unitsAvailable' in value) || value['unitsAvailable'] === undefined) return false;
    if (!('rentalEstimates' in value) || value['rentalEstimates'] === undefined) return false;
    return true;
}

export function FloorPlanFromJSON(json: any): FloorPlan {
    return FloorPlanFromJSONTyped(json, false);
}

export function FloorPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloorPlan {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'bedrooms': json['bedrooms'],
        'bathrooms': json['bathrooms'],
        'sqft': json['sqft'],
        'listedPrice': json['listed_price'],
        'unitsAvailable': json['units_available'],
        'rentalEstimates': ((json['rental_estimates'] as Array<any>).map(RentalEstimateFromJSON)),
    };
}

export function FloorPlanToJSON(json: any): FloorPlan {
    return FloorPlanToJSONTyped(json, false);
}

export function FloorPlanToJSONTyped(value?: FloorPlan | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'bedrooms': value['bedrooms'],
        'bathrooms': value['bathrooms'],
        'sqft': value['sqft'],
        'listed_price': value['listedPrice'],
        'units_available': value['unitsAvailable'],
        'rental_estimates': ((value['rentalEstimates'] as Array<any>).map(RentalEstimateToJSON)),
    };
}

