/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertyListAddPropertiesRequest
 */
export interface PropertyListAddPropertiesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyListAddPropertiesRequest
     */
    propertyIds: Array<string>;
}

/**
 * Check if a given object implements the PropertyListAddPropertiesRequest interface.
 */
export function instanceOfPropertyListAddPropertiesRequest(value: object): value is PropertyListAddPropertiesRequest {
    if (!('propertyIds' in value) || value['propertyIds'] === undefined) return false;
    return true;
}

export function PropertyListAddPropertiesRequestFromJSON(json: any): PropertyListAddPropertiesRequest {
    return PropertyListAddPropertiesRequestFromJSONTyped(json, false);
}

export function PropertyListAddPropertiesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyListAddPropertiesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'propertyIds': json['property_ids'],
    };
}

export function PropertyListAddPropertiesRequestToJSON(json: any): PropertyListAddPropertiesRequest {
    return PropertyListAddPropertiesRequestToJSONTyped(json, false);
}

export function PropertyListAddPropertiesRequestToJSONTyped(value?: PropertyListAddPropertiesRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'property_ids': value['propertyIds'],
    };
}

