/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyToJSONTyped,
} from './Company';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    AddressToJSONTyped,
} from './Address';
import type { PropertyOfficeHours } from './PropertyOfficeHours';
import {
    PropertyOfficeHoursFromJSON,
    PropertyOfficeHoursFromJSONTyped,
    PropertyOfficeHoursToJSON,
    PropertyOfficeHoursToJSONTyped,
} from './PropertyOfficeHours';
import type { PointOfInterest } from './PointOfInterest';
import {
    PointOfInterestFromJSON,
    PointOfInterestFromJSONTyped,
    PointOfInterestToJSON,
    PointOfInterestToJSONTyped,
} from './PointOfInterest';
import type { FloorPlan } from './FloorPlan';
import {
    FloorPlanFromJSON,
    FloorPlanFromJSONTyped,
    FloorPlanToJSON,
    FloorPlanToJSONTyped,
} from './FloorPlan';

/**
 * 
 * @export
 * @interface PropertyResponse
 */
export interface PropertyResponse {
    /**
     * 
     * @type {string}
     * @memberof PropertyResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyResponse
     */
    phoneNumber: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyResponse
     */
    images: Array<string>;
    /**
     * The type of property
     * @type {string}
     * @memberof PropertyResponse
     */
    type: PropertyResponseTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyResponse
     */
    amenities: Array<PropertyResponseAmenitiesEnum>;
    /**
     * The lease terms of the property
     * @type {Array<number>}
     * @memberof PropertyResponse
     */
    leaseTerms: Array<number>;
    /**
     * 
     * @type {Array<PropertyOfficeHours>}
     * @memberof PropertyResponse
     */
    officeHours: Array<PropertyOfficeHours>;
    /**
     * 
     * @type {Array<PointOfInterest>}
     * @memberof PropertyResponse
     */
    pointsOfInterest?: Array<PointOfInterest>;
    /**
     * 
     * @type {Array<FloorPlan>}
     * @memberof PropertyResponse
     */
    floorPlans: Array<FloorPlan>;
    /**
     * 
     * @type {Address}
     * @memberof PropertyResponse
     */
    address: Address;
    /**
     * 
     * @type {Company}
     * @memberof PropertyResponse
     */
    company: Company;
    /**
     * Indicates whether the property is favorited by the user
     * @type {boolean}
     * @memberof PropertyResponse
     */
    favorite: boolean;
    /**
     * Indicates whether the user has sent a message to this property
     * @type {boolean}
     * @memberof PropertyResponse
     */
    sentMessage: boolean;
}


/**
 * @export
 */
export const PropertyResponseTypeEnum = {
    House: 'House',
    Apartment: 'Apartment',
    Townhouse: 'Townhouse',
    Condo: 'Condo'
} as const;
export type PropertyResponseTypeEnum = typeof PropertyResponseTypeEnum[keyof typeof PropertyResponseTypeEnum];

/**
 * @export
 */
export const PropertyResponseAmenitiesEnum = {
    Pool: 'Pool',
    FitnessCenter: 'Fitness Center',
    Elevator: 'Elevator',
    Clubhouse: 'Clubhouse',
    ControlledAccess: 'Controlled Access',
    Recycling: 'Recycling',
    BusinessCenter: 'Business Center',
    Grill: 'Grill'
} as const;
export type PropertyResponseAmenitiesEnum = typeof PropertyResponseAmenitiesEnum[keyof typeof PropertyResponseAmenitiesEnum];


/**
 * Check if a given object implements the PropertyResponse interface.
 */
export function instanceOfPropertyResponse(value: object): value is PropertyResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('images' in value) || value['images'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('amenities' in value) || value['amenities'] === undefined) return false;
    if (!('leaseTerms' in value) || value['leaseTerms'] === undefined) return false;
    if (!('officeHours' in value) || value['officeHours'] === undefined) return false;
    if (!('floorPlans' in value) || value['floorPlans'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('favorite' in value) || value['favorite'] === undefined) return false;
    if (!('sentMessage' in value) || value['sentMessage'] === undefined) return false;
    return true;
}

export function PropertyResponseFromJSON(json: any): PropertyResponse {
    return PropertyResponseFromJSONTyped(json, false);
}

export function PropertyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'phoneNumber': json['phone_number'],
        'images': json['images'],
        'type': json['type'],
        'amenities': json['amenities'],
        'leaseTerms': json['lease_terms'],
        'officeHours': ((json['office_hours'] as Array<any>).map(PropertyOfficeHoursFromJSON)),
        'pointsOfInterest': json['points_of_interest'] == null ? undefined : ((json['points_of_interest'] as Array<any>).map(PointOfInterestFromJSON)),
        'floorPlans': ((json['floor_plans'] as Array<any>).map(FloorPlanFromJSON)),
        'address': AddressFromJSON(json['address']),
        'company': CompanyFromJSON(json['company']),
        'favorite': json['favorite'],
        'sentMessage': json['sent_message'],
    };
}

export function PropertyResponseToJSON(json: any): PropertyResponse {
    return PropertyResponseToJSONTyped(json, false);
}

export function PropertyResponseToJSONTyped(value?: PropertyResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'phone_number': value['phoneNumber'],
        'images': value['images'],
        'type': value['type'],
        'amenities': value['amenities'],
        'lease_terms': value['leaseTerms'],
        'office_hours': ((value['officeHours'] as Array<any>).map(PropertyOfficeHoursToJSON)),
        'points_of_interest': value['pointsOfInterest'] == null ? undefined : ((value['pointsOfInterest'] as Array<any>).map(PointOfInterestToJSON)),
        'floor_plans': ((value['floorPlans'] as Array<any>).map(FloorPlanToJSON)),
        'address': AddressToJSON(value['address']),
        'company': CompanyToJSON(value['company']),
        'favorite': value['favorite'],
        'sent_message': value['sentMessage'],
    };
}

