/* tslint:disable */
/* eslint-disable */
/**
 * Vercasa API
 * Vercasa API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A list of properties with a name created by the user
 * @export
 * @interface PropertyListResponse
 */
export interface PropertyListResponse {
    /**
     * 
     * @type {string}
     * @memberof PropertyListResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyListResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyListResponse
     */
    propertyCount: number;
    /**
     * 
     * @type {Date}
     * @memberof PropertyListResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PropertyListResponse
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the PropertyListResponse interface.
 */
export function instanceOfPropertyListResponse(value: object): value is PropertyListResponse {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('propertyCount' in value) || value['propertyCount'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function PropertyListResponseFromJSON(json: any): PropertyListResponse {
    return PropertyListResponseFromJSONTyped(json, false);
}

export function PropertyListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'propertyCount': json['property_count'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function PropertyListResponseToJSON(json: any): PropertyListResponse {
    return PropertyListResponseToJSONTyped(json, false);
}

export function PropertyListResponseToJSONTyped(value?: PropertyListResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'property_count': value['propertyCount'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
    };
}

