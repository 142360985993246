import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import PageTitle from '../../components/PageTitle.tsx';
import {useAuth} from '../../contexts/AuthContext.tsx';
import LogoFullBlue from '../../images/logo/logo-full-blue.svg';
import LogoFullAcent1 from '../../images/logo/logo-full-accent1.svg';
import DefaultLayout from '../../layout/DefaultLayout.tsx';
import {useLoading} from "../../contexts/LoadingContext.tsx";
import {Lock, Mail} from "lucide-react";

const SignIn: React.FC = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string>('');
    const {isLoading} = useLoading();

    const {login} = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            await login(email, password);
        } catch (error) {
            setError('Something went wrong. Please try again.');
        }
    };

    return (
        <DefaultLayout>
            <PageTitle title="Sign In"/>

            <div
                className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                <div className="flex flex-wrap items-center">
                    <div className="hidden w-full xl:block xl:w-1/2">
                        <div className="py-17.5 px-26 text-center">
                            <Link className="mb-5.5 inline-block" to="/">
                                <img className="hidden dark:block h-25" src={LogoFullAcent1} alt="Vercasa acent logo"/>
                                <img className="dark:hidden h-25" src={LogoFullBlue} alt="Vercasa blue logo"/>
                            </Link>

                            <p className="2xl:px-20 dark:text-secondary">Profitable short-term rental opportunities.</p>
                        </div>
                    </div>

                    <div className="w-full border-stroke dark:border-strokedark xl:w-1/2 xl:border-l-2">
                        <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                            <span className="mb-1.5 block font-medium">Access your account</span>
                            <h2 className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2">
                                Log in
                            </h2>

                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                                        Email
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="email"
                                            placeholder="Enter your email"
                                            className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-accent-3"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <Mail size={18} className="absolute right-4 top-4"/>
                                    </div>
                                </div>

                                <div className="mb-6">
                                    <label className="mb-2.5 block font-medium text-black dark:text-white">
                                        Password
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="password"
                                            placeholder="Enter your password"
                                            className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-accent-3"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />

                                        <Lock size={18} className="absolute right-4 top-4"/>
                                    </div>
                                </div>

                                {error && (
                                    <div className="mb-4 text-danger">{error}</div>
                                )}

                                <div className="mb-5">
                                    <input
                                        type="submit"
                                        value={isLoading ? 'Signing In...' : 'Sign In'}
                                        className="w-full font-bold cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90 dark:bg-accent-3"
                                        disabled={isLoading}
                                    />
                                </div>

                                <div className="mt-6 text-center">
                                    <p>
                                        <span className='dark:text-white'>Don't have any account?</span>
                                        {' '}
                                        <Link to="/auth/signup" className="text-primary dark:text-accent-2">
                                            Sign Up
                                        </Link>
                                    </p>

                                    <p className="mt-4">
                                        <Link to="/auth/forgot-password" className="text-primary dark:text-accent-2">
                                            Forgot Password
                                        </Link>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default SignIn;