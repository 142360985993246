import React, {ReactNode} from 'react';
import Header from '../components/Header/index';
import Sidebar from '../components/Sidebar/index';
import useLocalStorage from "../hooks/useLocalStorage.tsx";

const MapLayout: React.FC<{ children: ReactNode }> = ({children}) => {
    const [storedSidebarExpanded, setStoredSidebarExpanded] = useLocalStorage<string | null>('sidebar-expanded', null);

    const isSidebarOpen = () => {
        return storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    };

    const handleToggleSidebar = () => {
        setStoredSidebarExpanded(`${!isSidebarOpen()}`);
    };

    return (
        <div className="dark:bg-boxdark-2 dark:text-bodydark">
            <div className="flex h-screen overflow-hidden">
                <Sidebar sidebarOpen={isSidebarOpen()} toggleSidebar={handleToggleSidebar}/>
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    <Header sidebarOpen={isSidebarOpen()} toggleSidebar={handleToggleSidebar}/>
                    <main className="flex-1">
                        {children}
                    </main>
                </div>
            </div>
        </div>
    );
};

export default MapLayout;
